<template>
  <div class="page-reviews-stores">
    <back />

    <page-title title="Отзывы" description="Впечатления и опыт покупателей магазина" />

    <div class="avatar">
      <n-loader :loading="$var('loadAvatar')" />
      <img :src="shop.avatar" alt="">
    </div>

    <div class="container">
      <div class="wrap">
        <div class="title-wrap">
          <h2>{{ shop.title }}</h2>
        </div>

        <div class="reviews">
          <div>
            <star-rating :read-only="true" :star-size="15" :rating="shop.rating" :increment="0.01"></star-rating>
          </div>
        </div>
      </div>

      <div class="content">
        <n-loader :loading="$var('loadReview')" />
        <review :reviews="reviews" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PageReviewsStores',
  data() {
    return {
      reviews: [],
      shop: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('loadReview', true)
      this.$var('loadAvatar', true)

      $api.app.reviews.get(this.id).with('author').with('target').then((response)=>{
        this.reviews = response.data.content
      }).finally(() => {
        this.$var('loadReview', false)
      })

      $api.app.stores.get(this.id).then((response) => {
        this.shop = response.data.content
      }).finally(() => {
        this.$var('loadAvatar', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-reviews-stores {
  .avatar{
    max-height: 290px;
    min-height: 240px;
    margin-bottom: 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      max-width: 100%;
    }
  }

  &::v-deep {
    .page-title {
      margin-bottom: 0;
    }
  }

  .slider {
    padding-bottom: 25px;
  }

  .swiper-slide {
    height: 230px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .wrap {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 8px;
    margin-bottom: 25px;
  }

  .content {
    position: relative;
  }
}
</style>
